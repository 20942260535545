import React from 'react';
import styled from 'styled-components';
import GlobalStyles from '../styles/GlobalStyles';
import colors from '../styles/colors';

import bg from '../images/bg.png';

const StyledNotFound = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width:100vw;
    height: 100vh;
    overflow-x: hidden;
    margin:0;
    background: url(${bg});
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    font-size: 1em;
    color: ${colors.main_text};
`;

const NotFound = () => (
  <>
    <GlobalStyles />
    <StyledNotFound>
      <h1>Not Found.</h1>

    </StyledNotFound>
  </>
);

export default NotFound;
